<template>
  <web v-if="!isH5()"/>
  <h5-view v-else/>
</template>

<script setup>
import Web from './component/web/index.vue'
import H5View from './component/h5/index.vue'

const isH5 = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopIndex",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
