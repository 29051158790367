<template>
  <div class="h5-index">
    <h5-header type="index"/>
    <h5-page-one/>
    <h5-page-two />
    <h5-page-que />
    <h5-page-three />
    <h5-video-page />
    <h5-page-four />
    <h5-footer/>
    <top-btn />
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, onUnmounted} from "vue";
import H5PageOne from "@views/shop/index/component/h5/component/h5PageOne.vue";
import H5Header from '@views/shop/h5Components/h5Header/index.vue'
import H5PageTwo from "@views/shop/index/component/h5/component/h5PageTwo.vue";
import H5PageThree from "@views/shop/index/component/h5/component/h5PageThree.vue";
import H5VideoPage from "@views/shop/index/component/h5/component/h5VideoPage.vue";
import H5PageQue from "@views/shop/index/component/h5/component/h5PageQue.vue";
import H5PageFour from "@views/shop/index/component/h5/component/pageFour.vue";
import H5Footer from '@views/shop/h5Components/h5Footer/index.vue';
import TopBtn from "@views/shop/h5Components/topBtn/index.vue";
import {setRem,addMeta,removeMeta} from '@utils/tool.js';
import {useEventListener} from "@utils/tool/event";
addMeta('viewport',"width=device-width, initial-scale=1",'metaPhone')
useEventListener(window, 'resize', setRem)
onMounted(() => {
  setRem()
})
const {proxy} = getCurrentInstance();
onUnmounted(() => {
  document.documentElement.style.fontSize = '14px';
  removeMeta('metaPhone')
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5Index",
});
</script>

<style lang="scss" src="@views/shop/assets/common-h5.scss" scoped>

</style>
