<template>
  <div id="WYLG_WEB" v-cloak>
    <start-video v-if="showVideoDia"/>
    <shop-header type="index"></shop-header>
    <div class="page item1">
      <div class="content">
        <div class="top-title">
          <span class="left">纸盒仓储</span><span class="white">管理系统</span>
        </div>
        <div class="top-sub-title">CARTON WAREHOUSE MANAGEMENT</div>
        <div class="top-sub-text">遇见{{ ' ' }}·{{ ' ' }}前所未见</div>
      </div>
    </div>
    <div class="page item2">
      <div class="text-left-in fade-in-box" ref="showScreenIn">
        <div class="top sub-title">纸盒是什么？</div>
        <div class="middle">采用<span class="bule">RFID射频感应技术</span></div>
        <div class="buttom">的仓储管理平台</div>
      </div>
      <div class="text-right-in fade-in-box">
        <div class="top ">通过RFID手持设备快速扫描群读识别产品信息</div>
        <div class="middle bold">高精准、高效率、高质量</div>
        <div class="buttom">地完成每个业务场景</div>
      </div>
    </div>
    <div class="page item5" v-loading="loading">
      <div class="page-title">算算使用纸盒能省多少钱?</div>
      <div class="question fade-in-box">
        <div class="question-item" v-show='question === 1'>
          <div class="q-t">01. 请输入您的仓库总面积</div>
          <div class="q-input">
            <input type="number" onkeyup="value=value.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')"
                   v-model.number="form.area" maxlength="10" class="wy-input no-number"/>
            <span>m²</span>
          </div>
        </div>
        <div class="question-item" v-show='question === 2'>
          <div class="q-t">02. 输入仓库工作人员数量</div>
          <div class="q-input">
            <input type="number" v-model.number="form.people" maxlength="10" class="wy-input no-number"/>
            <span>人</span>
          </div>
        </div>
        <div class="question-item" v-show='question === 3'>
          <div class="q-t">03. 输入人均月工资</div>
          <div class="q-input">
            <input type="number" v-model.number="form.salary" maxlength="10" class="wy-input no-number"/>
            <span>元</span>
          </div>
        </div>
        <div class="question-item" v-show='question === 4'>
          <div class="q-t">04.输入日均销售数量</div>
          <div class="q-input">
            <input type="number" v-model.number="form.sale" maxlength="10" class="wy-input no-number"/>
            <span>个</span>
          </div>
        </div>
        <div class="question-item" v-show='question === 5'>
          <div class="q-t"></div>
          <div class="result" v-if="saveMoney">通过计算，使用本平台可帮助企业节约支出成本 <span
              v-increase="saveMoney">{{ saveMoney }}</span>元/年
          </div>
          <div class="result" v-else>通过计算，使用本平台可帮助企业提高100%效率</div>
        </div>
      </div>
      <div class="btn-box" v-if="question !== 5">
        <button class="wy-button big status1" @click="next" :disabled="question === 1">上一步</button>
        <button class="wy-button big status2" @click="next('next')">{{ question === 4 ? '查看结果' : '下一步' }}
        </button>
      </div>
      <div class="btn-box" v-else>
        <button class="wy-button big status2" @click="resetForm">重新填写</button>
      </div>
      <div class="page-info" v-if="question !== 5">
        <span class="choose-num">0{{ question }}</span><span class="line">/</span><span class="total-num">04</span>
      </div>
    </div>
    <div class="page item3">
      <div class="left-bg"></div>
      <div class="right-bg"></div>
    </div>
    <div class="page item7">
      <div class="page-title">你可能会遇到以下问题</div>
      <div class="content-text fade-in-box">
        <div class="text">无法精准检测产品积压，人工不易察觉</div>
        <div class="text">发现库存问题无法溯源，导致问题反复出现，增加损失</div>
        <div class="text dp_f">
          <div class="left">高单价产品无法有效管理，损失严重</div>
          <div class="right">闭店盘点，影响日常业务开展</div>
        </div>
        <div class="text">库存盘点费时费力易出错</div>
        <div class="text">无法精准管理效期，缺少自动提醒，库损较大</div>
        <div class="text">系统陈旧，操作繁琐易出错</div>
        <div class="text">仓库无序，人工寻货困难</div>
      </div>
    </div>
    <div class="page item4 dp_f">
      <div class="content">
        <div class="page-title">我们是如何</div>
        <div class="page-title mt_16">解决这些问题的</div>
        <ul class="tab-list">
          <li class="item" :class="videoTabChecked === index ?'active':''" @click="videoCheck(index)"
              v-for="(item,index) in videoList" :key="index">
            <span>{{ item.name }}</span>
            <img src="@img/shop/index/right.png" alt="位元灵感" class="icon-img"/>
          </li>
          <!--          <li class="item" :class="videoTabChecked === 3 ?'active':''" @click="videoCheck(3)">-->
          <!--            <span>辅助寻货</span>-->
          <!--          </li>-->
        </ul>
      </div>
      <div class="video-content" @mouseenter="mouseenter" @mouseleave="mouseleave">
        <video :src="videoUrl" type="video/mp4" ref="videoRef" class="video-box"/>
        <img v-show="showControl" :src="isPlayVideo" alt="位元灵感" @click="playVideo"
             class="control animate__animated animate__fadeIn"/>
        <div class="sub-text">{{ videoList[videoTabChecked].text }}</div>
      </div>
    </div>

    <div class="page item6">
      <div class="page-title">纸盒仓储管理系统</div>
      <div class="sub-title">高效率优选{{ ' ' }} 省钱好用</div>
      <div class="btn-box">
        <button class="wy-button middle" @click="learnMore(false)">了解更多</button>
        <button class="wy-button middle" @click="learnMore('concatUs')">联系我们</button>
      </div>
    </div>
    <shop-footer></shop-footer>
    <go-top></go-top>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, reactive, ref} from "vue";
import {isInViewport} from '@utils/tool/show.js';
import GoTop from "@views/shop/components/goTop.vue";
import ShopFooter from "@views/shop/components/shopFooter.vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import {useRouter} from "vue-router";
import {videoList} from '../../config.js'
import StartVideo from "@views/shop/index/component/web/startVideo.vue";
import useDiscounts from "@utils/tool/shop/useDiscounts";

const {form,resetForm,saveMoney,question,loading,next} = useDiscounts()
function isShowVideo() {
  if (!localStorage.getItem('WYLG_INDEX_VIDEO')) {
    localStorage.setItem('WYLG_INDEX_VIDEO', new Date().getDate() + '')
    return true
  } else if (localStorage.getItem('WYLG_INDEX_VIDEO') !== new Date().getDate() + '') {
    localStorage.setItem('WYLG_INDEX_VIDEO', new Date().getDate() + '')
    return true
  } else {
    return false
  }
}

const showVideoDia = isShowVideo()


const router = useRouter();
const showControl = ref(true)
const videoRef = ref(null)
const mouseenter = () => {
  let video = videoRef.value
  if (video && video.paused) return
  showControl.value = true
}
const mouseleave = () => {
  let video = videoRef.value
  if (video && video.paused) return
  showControl.value = false
}
const isPlay = ref(false)
const isPlayVideo = computed(() => {
  return !isPlay.value ? require('@img/shop/index/play.png') : require('@img/shop/index/pause.png')
})
const videoUrl = computed(() => {
  return videoList[videoTabChecked.value]?.url || ''
})
const {proxy} = getCurrentInstance();
const videoTabChecked = ref(0)
const videoCheck = (e) => {
  if (e === videoTabChecked.value) return
  isPlay.value = false
  showControl.value = true
  videoTabChecked.value = e
}
const playVideo = () => {
  const element = videoRef.value;
  if (element.paused) {
    isPlay.value = true
    element.play()
  } else {
    isPlay.value = false
    element.pause()
  }
}
onMounted(() => {
  const boxes = document.querySelectorAll('.fade-in-box')
  boxes.forEach(box => isInViewport(box))
  videoRef.value.onended = function () {
    isPlay.value = false
    videoRef.value.currentTime = 0
    showControl.value = true
  }
})
const learnMore = (concatUs) => {
  let query = {};
  if (concatUs) {
    query.tabLink = concatUs
  }
  router.push({
    path: '/shop/knowMore',
    query
  })
}

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopIndex",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
