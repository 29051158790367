<template>
  <div class="page question-page">
    <h2>算算使用纸盒能省多少钱？</h2>
    <div class="question fade-in-box">
      <div class="question-item" v-show='question === 1'>
        <div class="q-t">01. 请输入您的仓库总面积</div>
        <div class="q-input">
          <input type="number" onkeyup="value=value.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')"
                 v-model.number="form.area" maxlength="10" class="wy-input no-number"/>
          <span>m²</span>
        </div>
      </div>
      <div class="question-item" v-show='question === 2'>
        <div class="q-t">02. 输入仓库工作人员数量</div>
        <div class="q-input">
          <input type="number" v-model.number="form.people" maxlength="10" class="wy-input no-number"/>
          <span>人</span>
        </div>
      </div>
      <div class="question-item" v-show='question === 3'>
        <div class="q-t">03. 输入人均月工资</div>
        <div class="q-input">
          <input type="number" v-model.number="form.salary" maxlength="10" class="wy-input no-number"/>
          <span>元</span>
        </div>
      </div>
      <div class="question-item" v-show='question === 4'>
        <div class="q-t">04.输入日均销售数量</div>
        <div class="q-input">
          <input type="number" v-model.number="form.sale" maxlength="10" class="wy-input no-number"/>
          <span>个</span>
        </div>
      </div>
      <div class="question-item" v-show='question === 5'>
        <div class="q-t"></div>
        <div class="result" v-if="saveMoney">通过计算，使用本平台可帮助企业节约支出成本 <span
            v-increase="saveMoney">{{ saveMoney }}</span>元/年
        </div>
        <div class="result" v-else>通过计算，使用本平台可帮助企业提高100%效率</div>
      </div>
    </div>
    <div class="btn-box" v-if="question !== 5">
      <button class="wy-button big status1" @click="next" :disabled="question === 1">上一步</button>
      <button class="wy-button big status2" @click="next('next')">{{ question === 4 ? '查看结果' : '下一步' }}
      </button>
    </div>
    <div class="btn-box" v-else>
      <button class="wy-button big status2" @click="resetForm">重新填写</button>
    </div>
    <div class="page-info" v-if="question !== 5">
      <span class="choose-num">0{{ question }}</span><span class="line">/</span><span class="total-num">04</span>
    </div>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref} from "vue";

import useDiscounts from "@utils/tool/shop/useDiscounts";

const {form,resetForm,question,next ,saveMoney} = useDiscounts()
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5PageQue",
});
</script>
<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" scoped>
.page.question-page {
  height:8.8rem;
  background: #fff url("~@img/shop/h5/bg-ques.png") center center;
  background-size: contain;
  box-sizing: border-box;
  padding-top: 1.2rem;
  position: relative;
  h2{
    text-align: center;
    color: #fff;
  }
  .question-item {
    margin-top: 1.44rem;
    color: #fff;
    .q-t {
      line-height: 1;
      font-size: 0.3rem;
      text-align: center;
    }
    .result {
      font-size: 0.48rem;
      line-height: 2;
      margin-top: 0.2rem;
      text-align: center;
    }

    .q-input {
      display: flex;
      width: max-content;
      align-items: flex-end;
      margin: 0.62rem auto 0;
      border-bottom: 1px solid #fff;
      input {
        width: 3.2rem;
        font-size:0.56rem ;
      }
      span {
        font-size: 0.3rem;
        position: relative;
        bottom: 4px;
      }
    }
  }

  .btn-box {
    margin-top: 1.88rem;
    text-align: center;
    .wy-button {
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 0.28rem;
      &.status1 {
        margin-right: 0.32rem;
        width:2.4rem;
      }
      &.status2 {
        background-color: #fff;
        width: 3.6rem;
        color: $mainColor;
      }
    }
  }
  .page-info {
    position: absolute;
    right: 0.32rem;
    bottom: 0.32rem;
    > span {
      display: inline-block;
      line-height: 1;
    }
    .choose-num {
      font-size: 0.36rem;
      color: #fff;
    }

    .line {
      margin: 0 0.1rem ;
      font-size: 0.28rem;
      color: #B2C1FF;
      position: relative;
      top: -2px
    }

    .total-num {
      font-size: 0.28rem;
      color: #B2C1FF
    }
  }
}
</style>
