import {computed, reactive, ref} from "vue";
import resetMessage from '@/utils/tool/message';
import { showToast} from "vant";
const isH5 = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
const message=(msg)=>{
    if(isH5) {
        showToast(msg)
    }else {
        resetMessage.error(msg)
    }
}
export  default function (){
    const loading = ref(false)
    const question = ref(1)
    const form = reactive({
        //面积
        area: '',
        //人员数量
        people: '',
        //月工资
        salary: '',
        //日均销售量
        sale: '',
    })
    const resetForm  = ()=>{
        form.area = ''
        form.people = ''
        form.salary = ''
        form.sale = ''
        question.value = 1
    }
    const saveMoney = computed(() => {
        if (!form.area || !form.people || !form.salary || !form.sale) return false
        const area = Number(form.area)
        const people = Number(form.people)
        const salary = Number(form.salary)
        const sale = Number(form.sale)
        let total = ((people * salary * 12) / 2)  - ( Math.ceil(area / 200)  * 4000) - (sale * 0.3 * 365)
        if (total > 0) {
            return `${total.toFixed(2)}`
        } else {
            return 0
        }
    })
    const next = (type) => {
        if (type === 'next') {
            if (!form.area && question.value === 1) {
                return message('请输入您的仓库总面积')
            }
            if (!form.people && question.value === 2) {
                return message('请输入仓库工作人员数量')
            }
            if (!form.salary && question.value === 3) {
                return message('请输入人均月工资')
            }
            if (!form.sale && question.value === 4) {
                return message('请输入日均销售数量')
            }
            if (question.value === 4) {
                loading.value = true
                setTimeout(() => {
                    question.value += 1
                    loading.value = false
                }, 500)
                return
            }
            if (question.value < 5) {
                question.value += 1
            }
        } else {
            if (question.value > 1) {
                question.value--
            }
        }
    }
    return{
        next,
        loading,
        question,
        form,
        resetForm,
        saveMoney
    }
}