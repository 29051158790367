<template>
  <div class="page page1">
    <div class="top-title">遇见</div>
    <div class="top-sub-title">前所未见</div>
    <div class="top-sub-text">纸盒仓储管理系统</div>
    <img src="@img/shop/h5/arrow-down-page1.png" @click="handleClick" alt="位元灵感" class="more-img"/>
  </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";

const {proxy} = getCurrentInstance();
const handleClick = () => {
  //屏幕滚动一屏
  window.scrollTo({
    top: window.innerHeight,
    behavior: "smooth",
  });
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5PageOne",
});
</script>
<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" scoped>
.page1 {
  height: 100vh;
  background: #000 url("~@img/shop/h5/bg-page1.png") top center no-repeat;
  background-size: contain;
  position: relative;

  .more-img {
    width: 0.28rem;
    height: 0.28rem;
    position: absolute;
    bottom: 0.4rem;
    left: 50%;
    transform: translateX(-50%);
    animation: arrowMove 3s infinite;
    //循环
  }

  .top-title {
    position: absolute;
    bottom: 4.44rem;
    left: 0;
    width: 100%;
    font-size: 0.64rem;
    font-weight: 400;
    text-align: center;
    color: #fff
  }

  .top-sub-title {
    position: absolute;
    bottom: 2.83rem;
    left: 0;
    width: 100%;
    font-size: 1.04rem;
    font-weight: 600;
    text-align: center;
    color: #fff
  }

  .top-sub-text {
    position: absolute;
    bottom: 1.45rem;
    left: 0;
    width: 100%;
    font-size: 0.36rem;
    font-weight: 300;
    text-align: center;
    color: #fff;
  }
}

//写一个箭头轻微上下移动，渐隐渐现的动画
@keyframes arrowMove {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(0.2rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
