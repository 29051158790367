<template>
  <div class="page page4">
    <h2>纸盒仓储管理系统</h2>
    <div class="sub-title">高效率优选 省钱好用</div>
    <div class="route-list">
      <div class="single more cur_p" @click="goLink">
        <span>了解更多</span>
        <img src="@img/shop/h5/learn-index.png" alt="位元灵感"/>
      </div>
      <div class="single concat cur_p" @click="handleConcat">
        <img src="@img/shop/h5/concat-index.png" alt="位元灵感"/>
        <span>联系我们</span>
      </div>
    </div>
    <h5-concat-us v-model="showConcat"/>
  </div>
</template>

<script setup>
import {getCurrentInstance ,ref } from "vue";
import { useRouter } from "vue-router";
import H5ConcatUs from "@views/shop/h5Components/h5ConcatUs/index.vue";

const router = useRouter();
const showConcat = ref(false)
const {proxy} = getCurrentInstance();
const handleConcat = ()=>{
  showConcat.value = true
}
const goLink = ()=>{
  router.push({path:'/shop/knowMore'})
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5PageOne",
});
</script>
<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" scoped>
.page.page4 {
  height: 6rem;
  background:url("~@img/shop/h5/bg-page4.png") center center no-repeat;
  background-size: contain;
  text-align: center;
  padding-top: 1.66rem;
  box-sizing: border-box;
  color: #fff;
  .sub-title{
    margin-top: 0.48rem;
    font-size: 0.28rem;
  }
  .route-list{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.6rem;
    .single{
      font-size: 0.28rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &.more{
        color: $mainColor;
        img{
          width: 0.46rem;
          height: 0.18rem;
          margin-left: 0.16rem;
        }
      }
      &.concat{
        color: #fff;
        img{
          width: 0.28rem;
          height: 0.28rem;
          margin-right: 0.16rem;
        }
      }
      &:first-of-type{
        margin-right: 1.1rem;
      }
    }
  }
}
</style>
