<template>
  <div class="page page3">
    <h3>你可能会遇到以下问题</h3>
    <div class="text">无法精准监测产品积压，人工不易察觉</div>
    <div class="text">发现库存问题无法溯源，导致问题反复出现，增加损失</div>
    <div class="text">闭店盘点，影响日常业务开展</div>
    <div class="text">库存盘点费时费力易出错</div>
    <div class="text">无法精准管理效期，缺少自动提醒，库损较大</div>
    <div class="text">系统陈旧，操作繁琐易出错</div>
    <div class="text">仓库无序，人工寻货困难</div>
  </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";

const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5PageThree",
});
</script>
<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" scoped>
.page.page3 {
  height: 10.8rem;
  background: #1A1E25 url("~@img/shop/h5/bg-page3.png") center center no-repeat;
  background-size: contain;
  box-sizing: border-box;
  padding-top: 1.22rem;
  h3{
    color: #fff;
  }
  .text{
    line-height: 1;
    &:nth-of-type(1){
      font-size: 0.24rem;
      color: rgba(255,255,255,0.25);
      text-align: right;
      margin-top: 1.52rem;
    }&:nth-of-type(2){
      font-size: 0.26rem;
      color: rgba(255,255,255,0.5);
      text-align: left;
        margin-top: 0.48rem;
    }&:nth-of-type(3){
      font-size: 0.36rem;
      color: rgba(255,255,255,0.7);
      text-align: right;
    margin-top: 0.54rem;
    }&:nth-of-type(4){
      font-size: 0.6rem;
      color: rgba(255,255,255,1);
    margin-top: 0.68rem;
      text-align: center;
    }&:nth-of-type(5){
      font-size: 0.32rem;
      color: rgba(255,255,255,0.7);
      text-align: left;
    margin-top: 0.66rem;
    }&:nth-of-type(6){
      font-size: 0.26rem;
      color: rgba(255,255,255,0.5);
      text-align: right;
  margin-top: 0.51rem;
    }&:nth-of-type(7){
      font-size: 0.24rem;
      color: rgba(255,255,255,0.25);
      text-align: left;
      margin-top: 0.32rem;
      padding-left: 0.7rem;
    }
  }
}
</style>
