<template>
  <div class="page video-page">
    <h3>我们是如何解决这些问题的</h3>
    <swiper
    :slidesPerView="1.3"
    :spaceBetween="10"
    :pagination="{
      clickable: true,
    }"
    :loop='true'
    :modules="modules"
    class="video-swiper"
  >
    <swiper-slide v-for="(item,index) in videoList" :key="item.id" class="video-swiper-item ">
      <div class="video-content" @click.stop="playVideo(index)" :style="{background: `url(${item.bgUrl}) center center no-repeat `,backgroundSize:'cover'}">
        <div class="title" :class="index === 2 ?'white' :''" v-if="index !==2">{{ item.name }}</div>
        <div class="title white" v-else>
          <div class="top">追溯产品<span>生命周期</span></div>
        </div>
        <div v-html="item.vnode" v-if="index !==2"></div>
        <div v-else class="sub-desc">任一件货品，自入库至出库 的所有历史均有记录。</div>
          <img src="@img/shop/index/play-btn.png"  alt="位元灵感" class="play-img"/>
        </div>
    </swiper-slide>
  </swiper>
    <h5-video-player type="web" :video-url="videoUrl" v-model="showVideo"/>
  </div>
</template>

<script setup>
import {ref} from "vue";
import 'swiper/modules/pagination.css';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/swiper-vue';
import H5VideoPlayer from '@views/shop/h5Components/h5VideoPlayer/index.vue'
import {videoList} from '../../../config.js'
// Import Swiper styles
import 'swiper/swiper.scss';

const swiperRef = ref(null)
const modules =  [ Pagination];
const showVideo = ref(false)
const videoUrl = ref('')
const playVideo = (e)=>{
  videoUrl.value = videoList[e].url
  showVideo.value = true
}
</script>
<script>
import {defineComponent} from "vue";
export default defineComponent({
  name: "h5VideoPage",
});
</script>
<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" scoped>
.page.video-page {
  background: #fff ;
  background-size: contain;
  box-sizing: border-box;
  padding-top: 1.2rem;
  padding-bottom: 0.6rem;
  .swiper-c{
    padding-right: 2.2rem;
  }
  .video-swiper{
    margin-top: 0.64rem;
    padding-bottom: 1rem;
    .video-swiper-item{
      //width: 5rem !important;
      .video-content{
        width: 4.6rem;
        height: 6rem;
        border-radius: 0.24rem;
        background-size: cover;
        position: relative;
        margin: 0 auto;
        padding: 1.26rem 0.4rem;
        box-sizing: border-box;
        box-shadow: 0 0.08rem 0.16rem 0 rgba(0,0,0,0.08);

        > .sub-desc {
          color: $shopFsColor2;
          font-size: 0.3rem;
          line-height: 1.6;
        }
        :deep(.sub-text){
          color:$shopFsColor2;
          font-size: 0.28rem;
          &.white{
            color: #fff;
          }
          span{
            color: $mainColor;
          }
          margin-bottom: 0.14rem;
        }
        .title{
          position: absolute;
          top: 0.48rem;
          left: 0.4rem;
          font-size: 0.48rem;
          font-weight: 600;
          color: $shopFsColor;
          padding-right: 0.3rem;
          line-height: 1.5;
          &.white{
            color: $shopFsColor;

            span {
              color: $mainColor;
            }
          }
        }
        .play-img{
          width: 0.8rem;
          height: 0.56rem;
          position: absolute;
          right: 0.4rem;
          bottom: 0.4rem;
        }
      }
    }
    :deep(.swiper-pagination .swiper-pagination-bullet){
      width: 0.32rem;
      height: 0.1rem;
      border-radius: 0.1rem;
      background: #DADCE0;
      opacity: 1;
      @include transitionView(0.1s);
      &.swiper-pagination-bullet-active{
        background: $mainColor;
        width: 0.56rem;
      }
    }

  }
}
</style>
