import {onUnmounted, ref} from 'vue';

export function useTimeOut() {
  const timer = ref(null);
  const setTimer = (callback, delay) => {
    timer.value = setTimeout(callback, delay);
  };
  const clearTimer = () => {
    clearTimeout(timer.value);
    timer.value = null;
  };
  onUnmounted(() => {
    clearTimer();
  });

  return { setTimer, clearTimer };
}
export function useInterval() {
  const timer = ref(null);
    const setTimer = (callback, delay) => {
    timer.value = setInterval(callback, delay);
  };
    const clearTimer = () => {
    clearInterval(timer.value);
    timer.value = null;
  };
  onUnmounted(() => {
      clearTimer();
  });

    return {setTimer, clearTimer};
}