import { ossUrl } from "@utils/api.js";

export const videoList = [
    {
        name: '智能预警',
        id: 1,
        url:ossUrl +'/WEB/assets/video/smart.mp4',
        text: '自动引导处理临期/积压货品，避免亏损。',
        bgUrl:ossUrl + '/WEB/assets/img/h5/index/ai-bg.png',
        vnode:`<div class="sub-text">自动引导处理<span>临期/积压</span>货品</div><div class="sub-text">避免亏损</div>`
    },
    {
        name: '一键扫描盘点',
        id: 2,
        url: ossUrl +'/WEB/assets/video/scan.mp4',
        text: '摆脱人工点货，无需锁库，百倍提速精准完成盘点。',
        bgUrl:ossUrl + '/WEB/assets/img/h5/index/high-bg.png',
        vnode:`<div class="sub-text">摆脱人工点货，无需锁库</div><div class="sub-text">百倍提速精准完成盘点</div>`
    },
    {
        name: '追溯产品生命周期',
        id: 3,
        url:ossUrl + '/WEB/assets/video/getLive.mp4',
        text: '任一件货品，自入库至出库的所有历史均有记录。',
        bgUrl:ossUrl + '/WEB/assets/img/h5/index/live-bg.png',
        vnode:`<div class="sub-text white">任一件货品</div><div class="sub-text white">自入库至出库的所有历史均有记录</div>`
    },
]