<template>
  <div class="video-start animate__animated" id="video-cont" v-if="showVideo">
    <video preload="auto" muted loop="" playsinline="" class="animate__animated" ref="imgRefs" autoplay=""
           :src="`${ossUrl}/WEB/assets/video/start-video.mp4`"/>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, onUnmounted, ref} from "vue";
import {useTimeOut} from "@utils/tool/useTimer";
import {ossUrl} from "@utils/api";

const imgRefs = ref(null)
const showVideo = ref(true)
const {proxy} = getCurrentInstance();
const {setTimer, clearTimer} = useTimeOut()

onMounted(() => {
  imgLoadFun()
})
onUnmounted(() => {
  clearTimer()
})
//监听图片是否在家完成
const imgLoad = ref(false)
const imgLoadFun = () => {
  // document.body.style.height= '100vh'
  // document.body.style.overflow = 'hidden'
  const dom = imgRefs.value
  const dom2 = document.getElementById('video-cont')
  //监听dom 的视频是否加载完成
  dom.addEventListener('canplaythrough', function () {
    dom.play()
    setTimer(() => {
      dom.classList.add("animate__fadeOut")
      dom2.classList.add("animate__fadeOut")
      setTimer(() => {
        // document.body.style.height= 'auto'
        // document.body.style.overflow = 'auto'
        showVideo.value = false
      }, 1000)
    }, 5000)
  }, false);
}


</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "",
});
</script>

<style lang="scss" scoped>
.video-start {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #000;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
