<template>
  <div class="page page2">
    <h3>纸盒是什么？</h3>
    <div class="page-h2 ">采用<span class="bule">RFID射频感应技术</span></div>
    <div class="page-h2"> 的仓储管理平台 </div>
    <h4 class="page-h4">
      通过RFID手持设备快速扫描群读识别产品信息
    </h4>
    <h4 class="page-h4">
      高精准、高效率、高质量
    </h4>
    <h4 class="page-h4">
      地完成每个业务场景
    </h4>
  </div>
</template>

<script setup>
import {getCurrentInstance} from "vue";

const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "h5PageTwo",
});
</script>
<style lang="scss" scoped src="@views/shop/assets/common-h5.scss"></style>
<style lang="scss" scoped>
.page.page2 {
  height: 8.9rem;
  background: #fff url("~@img/shop/h5/bg-page2.png") center center no-repeat;
  background-size: contain;
  box-sizing: border-box;
  padding: 15% 0 0 5%;
  .page-h2{
    margin-top: 0.1rem;
    font-weight: 600;
   &:first-of-type{
     margin-top: 0.4rem;
   }
  }
  .page-h4{
    margin-top: 0.2rem;
    &:first-of-type{
      margin-top: 2.3rem;
    }
  }
}
</style>
