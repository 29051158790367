const root = document.getElementById('app')[0]
const opts = {
    root: root, //设置根元素
    threshold: [0.5],//交会处,目标元素与根元素相交区域为目标元素的50%时 这里是一个数组可以指定多个比例类似[0.25, 0.5, 0.75, 1]
    rootMargin: "0px"//对根元素收缩或者扩张
}

export function isInViewport(element) {
    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                element.classList.add('active')
            } else {
                // element.classList.remove('active')
            }
        },
        opts
    );
    observer.observe(element);
}